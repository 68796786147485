<template>
  <div class="hovering__pad py-3">
    <div class="px-3 mb-3 d-flex">
      <b-button
        @click="
          () => {
            model = {};
            action = 'create';
            $bvModal.show('create-course');
          }
        "
        class="mr-3"
        variant="outline-default"
        >Добавить</b-button
      >
    </div>
    <b-table v-if="data" :fields="fields" :items="data" stacked="sm" responsive>
      <template #cell(link)="data">
        <b-button
          variant="link"
          :href="`${window.location.origin}${window.location.pathname}#/education/tests/courses/${data.item.name}/?api_key=1b4b5add-3967-40c0-adc4-d6e66a264f68`"
          >{{
            `${window.location.origin}${window.location.pathname}#/education/tests/courses/${data.item.name}/?api_key=1b4b5add-3967-40c0-adc4-d6e66a264f68`
          }}</b-button
        >
      </template>
      <template #cell(del)="data">
        <b-button
          style="height: 33.5px"
          variant="outline-default"
          @click="
            () => {
              model = data.item;
              processCourse('delete');
            }
          "
          ><img style="width: 18px" src="@main/assets/img/svg/redcross.svg" alt=""
        /></b-button>
      </template>
      <template #cell(edit)="data">
        <b-button
          variant="outline-default"
          @click="
            () => {
              action = 'update';
              model = data.item;
              $bvModal.show('create-course');
            }
          "
          >✏️</b-button
        >
      </template>
      <template #cell(icon)="data"
        ><img v-if="data.value" :src="data.value" style="width: 45px; height: 45px" alt="" />
        <p v-else>Нет иконки</p></template
      >
    </b-table>
    <loader v-else></loader>
    <b-modal @ok="() => processCourse(action)" size="lg" id="create-course">
      <meta-data-form ref="mtf" :metadata="schema" v-model="model"></meta-data-form>
    </b-modal>
  </div>
</template>

<script>
import education from '@main/api/education';
import parsers from '@main/func/parsers';
import Loader from '../Loader.vue';
import MetaDataForm from '../reusable/forms/MetaDataForm.vue';

export default {
  components: { Loader, MetaDataForm },
  data: () => ({
    params: { page: 0 },
    loading: false,
    data: null,
    schema: null,
    model: null,
    action: 'create',
  }),
  computed: {
    fields() {
      return [
        // { key: '_id', label: 'ID' },
        {
          key: 'test_names',
          label: 'Тесты, входящие в курс',
          formatter(val, key, item) {
            return val ? val.join(' ,') : 'Не указаны';
          },
        },
        { key: 'name', label: 'Системное имя курса' },
        { key: 'display_name', label: 'Отображаемое имя курса' },
        { key: 'link', label: 'Ссылка на курс' },
        { key: 'icon', label: 'Иконка' },
        { key: 'del', label: 'Удалить' },
        { key: 'edit', label: 'Изменить' },
      ];
    },
  },
  methods: {
    async updateData() {
      this.loading = true;
      try {
        const result = await education.v2.tests.courses.list(this.params);
        this.data = result.data_array;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async processCourse(action) {
      console.log(this.model);
      await education.v2.tests.courses[action](this.model);
      if (action === 'update') {
        this.action = 'create';
      }
      await this.updateData();
    },
  },
  async mounted() {
    await this.updateData();
    const schema = await education.v2.models.schema.get('Course');
    this.schema = parsers.pydanticMetadataConverter(schema).actions.POST;
    this.model = parsers.djangoRestFrameworkMetadataParser(this.schema);
  },
};
</script>
