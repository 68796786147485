var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hovering__pad py-3"},[_c('div',{staticClass:"px-3 mb-3 d-flex"},[_c('b-button',{staticClass:"mr-3",attrs:{"variant":"outline-default"},on:{"click":function () {
          _vm.model = {};
          _vm.action = 'create';
          _vm.$bvModal.show('create-course');
        }}},[_vm._v("Добавить")])],1),(_vm.data)?_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.data,"stacked":"sm","responsive":""},scopedSlots:_vm._u([{key:"cell(link)",fn:function(data){return [_c('b-button',{attrs:{"variant":"link","href":("" + (_vm.window.location.origin) + (_vm.window.location.pathname) + "#/education/tests/courses/" + (data.item.name) + "/?api_key=1b4b5add-3967-40c0-adc4-d6e66a264f68")}},[_vm._v(_vm._s(("" + (_vm.window.location.origin) + (_vm.window.location.pathname) + "#/education/tests/courses/" + (data.item.name) + "/?api_key=1b4b5add-3967-40c0-adc4-d6e66a264f68")))])]}},{key:"cell(del)",fn:function(data){return [_c('b-button',{staticStyle:{"height":"33.5px"},attrs:{"variant":"outline-default"},on:{"click":function () {
            _vm.model = data.item;
            _vm.processCourse('delete');
          }}},[_c('img',{staticStyle:{"width":"18px"},attrs:{"src":require("@main/assets/img/svg/redcross.svg"),"alt":""}})])]}},{key:"cell(edit)",fn:function(data){return [_c('b-button',{attrs:{"variant":"outline-default"},on:{"click":function () {
            _vm.action = 'update';
            _vm.model = data.item;
            _vm.$bvModal.show('create-course');
          }}},[_vm._v("✏️")])]}},{key:"cell(icon)",fn:function(data){return [(data.value)?_c('img',{staticStyle:{"width":"45px","height":"45px"},attrs:{"src":data.value,"alt":""}}):_c('p',[_vm._v("Нет иконки")])]}}],null,false,806435949)}):_c('loader'),_c('b-modal',{attrs:{"size":"lg","id":"create-course"},on:{"ok":function () { return _vm.processCourse(_vm.action); }}},[_c('meta-data-form',{ref:"mtf",attrs:{"metadata":_vm.schema},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }